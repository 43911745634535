import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Heading, Button, Text, Grid, Paragraph } from "grommet"
import { ResponsiveContext } from "grommet"
import ServiceBenefits from "../../components/servicebenefits"

import Seo from "../../components/seo"
import circular from "../../static/images/circular.gif"
import fist from "../../static/images/fist.jpeg"
import cubes from "../../static/images/cubes.gif"

import Layout from "../../components/layout"

export default function Mindsets() {
  let mesh =
    "radial-gradient(at 82% 97%, hsla(70,77%,73%,1) 0, transparent 54%), radial-gradient(at 4% 83%, hsla(54,99%,69%,1) 0, transparent 45%), radial-gradient(at 52% 66%, hsla(115,78%,62%,1) 0, transparent 40%), radial-gradient(at 11% 81%, hsla(208,64%,76%,1) 0, transparent 52%), radial-gradient(at 18% 23%, hsla(103,93%,76%,1) 0, transparent 56%), radial-gradient(at 29% 82%, hsla(286,62%,79%,1) 0, transparent 54%), radial-gradient(at 91% 72%, hsla(158,75%,64%,1) 0, transparent 45%)"

  return (
    <Layout>
      <Seo title="Shape mindsets" />

      <Box>
        <Box
          width="xlarge"
          margin={{ top: "medium" }}
          alignSelf="center"
          align="center"
          pad={{ horizontal: "medium" }}
        >
          <Heading
            margin={{ top: "large", bottom: "none" }}
            level="1"
            size="large"
            textAlign="center"
          >
            Become a leader in the future economy
          </Heading>
          <Paragraph
            size="large"
            margin={{ bottom: "medium" }}
            textAlign="center"
          >
            Get hands on with the tools of the future in one day masterclasses
            and multi-day bootcamps.
          </Paragraph>
          <Link color="blue" to="/contact" >
            <Button
              size="large"
              primary
              color="brand"
              label="Speak to the team"
            />
          </Link>
        </Box>
      </Box>
      <Box
        border={{ side: "between", color: "light-3" }}
        width="xlarge"
        alignSelf="center"
        justify="around"
        direction="row-responsive"
        gap="medium"
        margin={{ vertical: "xlarge" }}
      >
        <Box basis="1/4" align="center" margin={{ bottom: "small" }}>
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            4
          </Text>
          <Paragraph textAlign="center" size="small">
            modular masterclasses
          </Paragraph>
        </Box>
        <Box basis="1/4" align="center" margin={{ bottom: "small" }}>
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            50+
          </Text>
          <Paragraph textAlign="center" size="small">
            unique activities mastering the tools of the future
          </Paragraph>
        </Box>
        <Box basis="1/4" align="center" margin={{ bottom: "small" }}>
          <Text
            weight={500}
            textAlign="center"
            margin={{ top: "medium", bottom: "none" }}
            size="xlarge"
          >
            10&ndash;30
          </Text>
          <Paragraph textAlign="center" size="small">
            attendees per experience
          </Paragraph>
        </Box>
      </Box>
      <Box width="xlarge" alignSelf="center" pad={{ horizontal: "medium" }}>
        <Box width="large" alignSelf="center" margin={{ top: "medium" }}>
          <Heading
            level={3}
            margin={{ bottom: "medium" }}
            size="medium"
            textAlign="center"
          >
            Master the mindset of the future and redefine the way you work
          </Heading>
        </Box>

        <ResponsiveContext.Consumer>
          {size => (
            <Grid
              margin={{ vertical: "large" }}
              columns={size !== "small" ? "45%" : "100%"}
              gap="small"
            >
              <Box
                border={{
                  side: "all",
                  color: "brand",
                }}
                basis="1/2"
                height="600px"
                pad="large"
                justify="around"
                align="center"
              >
                <Heading
                  level={3}
                  textAlign="center"
                  margin={{ top: "small", bottom: "small" }}
                  size="large"
                >
                  Circular Innovation
                </Heading>
                <img alt="" src={circular} width={250} height={250} />
                <Paragraph textAlign="center">
                  Build sustainable ventures fit for the future of work.
                </Paragraph>
              </Box>
              <Box
                style={{
                  background: mesh,
                }}
                basis="1/2"
                height="600px"
                pad={{ horizontal: "xlarge", vertical: "large" }}
                justify="center"
                align="center"
              >
                <Heading
                  level={3}
                  textAlign="center"
                  margin={{ top: "small", bottom: "small" }}
                  size="large"
                >
                  Data deconstructed
                </Heading>
                <Text textAlign="center">
                  Learn how quantum, AI and emerging tech’ are changing the
                  future and why we have to be ethical by default
                </Text>
              </Box>
              <Box
                background={{
                  position: "top",
                  dark: true,
                  image: `url(${fist})`,
                  size: "cover",
                }}
                basis="1/2"
                height="600px"
                pad={{ horizontal: "medium", vertical: "large" }}
                align="center"
              >
                <Heading
                  level={3}
                  textAlign="center"
                  margin={{ top: "small", bottom: "small" }}
                  size="medium"
                >
                  Rebel mindset
                </Heading>
                <Text textAlign="center" style={{ maxWidth: "350px" }}>
                  Empower entrepreneurialism & purpose as a driving force in
                  your organisation
                </Text>
              </Box>{" "}
              <Box
                border={{
                  side: "all",
                  color: "brand",
                }}
                basis="1/2"
                height="600px"
                pad="large"
                align="center"
                justify="between"
              >
                <Heading
                  level={3}
                  textAlign="center"
                  margin={{ top: "small", bottom: "small" }}
                  size="xlarge"
                >
                  <code>
                    <strong>&lt;&thinsp;Web 3.0&thinsp;/&gt;</strong>
                  </code>
                </Heading>
                <img alt="" src={cubes} width={250} height={250} />

                <Text textAlign="center">
                  Understand how decentralising technologies are
                  re&#8209;shaping our world
                </Text>
              </Box>
            </Grid>
          )}
        </ResponsiveContext.Consumer>
        <Text textAlign="center">
          <Link color="blue" to="/contact" >
            <Button
              size="large"
              primary
              color="brand"
              label="Speak to the team"
            />
          </Link>
        </Text>
        <Box width="large" alignSelf="center" margin={{ top: "large" }}>
          <Heading
            level={3}
            margin={{ bottom: "medium" }}
            size="medium"
            textAlign="center"
          >
            Facilitated by experts, hackers and rebels
          </Heading>
          <Paragraph
            size="large"
            margin={{ bottom: "medium" }}
            textAlign="center"
          ></Paragraph>
        </Box>
        <Box width="xlarge" alignSelf="center" margin={{ bottom: "large" }}>
          <StaticImage
            alt="graphic"
            objectFit="cover"
            layout="fullWidth"
            placeholder="blurred"
            src="../../static/images/presenting.jpeg"
          />
        </Box>
        <Box alignSelf="center" width="xlarge">
          <ServiceBenefits/>
        </Box>
      </Box>
    </Layout>
  )
}
